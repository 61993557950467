.b_button_primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.b_button_secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}

.prime_card_container {
  scroll-behavior: smooth;
}

.header_align {
  text-align: center;
  width: 75%;
  margin: auto;
}
.outline-btn {
  border: 1px solid !important;
}
// background white
.bg_surface50 {
  background-color: var(--surface-50) !important;
}
// background light white
.bg_surface100 {
  background-color: var(--surface-100);
}

.bg_surface800 {
  background-color: var(--surface-800);
}
// color light gray
.text_surface400 {
  color: var(--surface-400);
}
.text_surface500 {
  color: var(--surface-500);
}

//  color black
.text_surface800 {
  color: var(--surface-800);
}
// color light black
.text_surface600 {
  color: var(--surface-600);
}


.testimonial_title {
  color: var(--surface-600);
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
}

@media (max-width: 640px) {
  .header_align {
    text-align: center;
    width: 100% !important;
    margin: auto;
  }
  .testimonial_title {
    color: var(--surface-600);
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.prime_navbar {
  border: 1px solid var(--surface-border);
  border-radius: 0px;
  background-color: var(--surface-card);
  position: relative;
  z-index: 0;
  padding: 1rem 1.5rem;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.02),
    0px 28px 28px 0px rgba(0, 0, 0, 0.02), 0px 63px 38px 0px rgba(0, 0, 0, 0.01);
}

.prime_card {
  border: 1px solid var(--surface-border);
  border-radius: 10px;
  background-color: var(--surface-card);
  position: relative;
  z-index: 0;
  padding: 1.5rem;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.02),
    0px 28px 28px 0px rgba(0, 0, 0, 0.02), 0px 63px 38px 0px rgba(0, 0, 0, 0.01);
}

.prime_card_container .prime_card:after,
.prime_card_container .prime_card:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid var(--primary-color);
  transition: all 0.5s;
  animation: clippath 3s linear infinite;
  border-radius: 10px;
  opacity: 0;
  z-index: -1;
}

.prime_card_container .prime_card:after {
  animation: clippath 3s linear -1.5s infinite;
}

.prime_card_container .prime_card:hover:after,
.prime_card_container .prime_card:hover:before {
  opacity: 1;
}

.animation-duration-500 {
  animation-duration: 0.5s !important;
}

@keyframes clippath {
  0%,
  to {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }

  50% {
    clip-path: inset(98% 0 0 0);
  }

  75% {
    clip-path: inset(0 0 0 98%);
  }
}

.prime_container {
  max-width: 1250px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-card .p-card-body,
.p-card .p-card-content {
  padding: 0;
}

.p-component {
  font-family: inherit;
}

.prime_card_0 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--surface-card) !important;
  position: relative;
  z-index: 0;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.02),
    0px 28px 28px 0px rgba(0, 0, 0, 0.02), 0px 63px 38px 0px rgba(0, 0, 0, 0.01);
}

.prime_card2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--surface-card);
  position: relative;
  z-index: 0;
  padding: 1.5rem;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.02),
    0px 28px 28px 0px rgba(0, 0, 0, 0.02), 0px 63px 38px 0px rgba(0, 0, 0, 0.01);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #d1d5db;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--primary-color);
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}
// .p-button {
//   padding: 0.55rem 1.2rem;
//   @media (min-width: 641px) and (max-width: 912px) {
//     padding: 0.6rem 1rem !important;
//   }
//   @media (max-width: 641px) {
//     padding: 0.5rem 1rem !important;
//   }
// }
.p-button.p-button-outlined:hover {
  background: rgba(59, 130, 246, 0.04) !important;
  border: 1px solid !important;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}
.p-button2 {
  width: 100% !important;
  padding: 0.75rem 1.25rem;
  @media (min-width: 641px) and (max-width: 912px) {
    padding: 0.6rem 1rem !important;
  }
  @media (max-width: 641px) {
    padding: 0.5rem 1rem !important;
  }
}
.p-button2.p-button-outlined:hover {
  background: rgba(59, 130, 246, 0.04) !important;
  border: 1px solid !important;
}

.p-button2.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #6b7280;
  background: #ffffff;
  font-weight: 700;
  // border-radius: 6px;
  transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  color: #4b5563;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-accordion .p-accordion-tab {
  margin-bottom: 8px;
}

.p-tabview-nav-container {
  position: relative;
}

.p-tabview-nav-content {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior: contain auto;
  position: relative;
}

.p-tabview .p-tabview-nav {
  background: transparent;
  border: 1px solid #e5e7eb;
  border-width: 0 0 2px 0;
}

.p-tabview-nav {
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1 1 auto;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-unselectable-text {
  user-select: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #e5e7eb;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: transparent;
  color: #6b7280;
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;

  @media (min-width: 641px) and (max-width: 912px) {
    padding: 0.75rem;
  }

  @media (max-width: 640px) {
    padding: 0.75rem;
  }
}

.p-tabview-nav-link {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 1.25rem;
  border: 0 none;
  color: #4b5563;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  @media (min-width: 641px) and (max-width: 912px) {
    padding: 0.75rem;
  }

  @media (max-width: 640px) {
    padding: 0.75rem;
  }
}

.p-button-label {
  font-weight: 500;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c !important;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #d1d5db !important;
}
.borderleft li {
  border-left: 1px solid #8080808f !important;
}
.p-inputtext {
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
}
.form1.p-inputtext {
  border: none !important;
}

.prime_password {
  width: 100% !important;
}

.prime_password .p-icon-field {
  width: 100% !important;
}

.prime_password .p-icon-field .p-password-input {
  width: 100% !important;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #e5e7eb;
}

.p-divider-horizontal:before {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  content: "";
}

.p-dropdown {
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  background: #ffffff;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 0.25rem 0.75rem !important;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6b7280;
  width: 2rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.p-radiobutton .p-radiobutton-box {
  
  border: 2px solid #d1d5db !important;

}