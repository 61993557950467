.top-right-8px {
  top: -8px;
  right: -8px;
}

.bg-f98c27 {
  background-color: #f98c27 !important;
}

.clr-f98c27 {
  color: #f98c27 !important;
}

.bg-0064b6 {
  background-color: #0064b6;
}

.bg-04bd00 {
  background-color: #04bd00;
}

.bg-053c6d {
  background-color: #053c6d !important;
}

.clr-053c6d {
  color: #053c6d !important;
}
.clr-232323 {
  color: #232323 !important;
}
.bg-ffeaa1 {
  background: #ffeaa1 !important;
}

.header-logo {
  width: 200px;
  height: 65px;

  @media (max-width: 576px) {
    width: 150px;
    height: 45px;
  }
}
.h-88vh {
  height: 88vh;

  @media (max-width: 821px) and (min-width: 575px) {
    height: 44vh;
  }
}

// .h-400px {
//   height: 400px !important;
// }
.h-business {
  height: 800px !important;
  @media (max-width: 768px) {
    height: 100% !important;
    background: var(--chakra-colors-secondary) !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .h-md-filter {
    height: 400px !important;
  }
}
.h-swiper {
  height: 450px !important;
  @media (max-width: 640px) {
    height: 300px !important;
  }
}

.icicdirection .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 50% !important;
  bottom: 50% !important;
  right: 4px !important;
  left: auto !important;
  width: auto !important;
  display: grid !important;
  gap: 6px !important;
}
.icicdirection .swiper-pagination-bullet-active {
  background: #fff !important;
}
.icicdirection2 .swiper-pagination-bullets.swiper-pagination-horizontal {
  right: auto !important;
  left: 20px !important;
  width: auto;
}
.icicdirection2 .swiper-pagination-bullet-active {
  background: #111 !important;
}
// .chakra-modal__header {
//   background: var(--chakra-colors-primary) !important;
// }
.mt-minus {
  margin-top: -10px !important;
}

.h-250px {
  height: 250px;
}
.clr-04bd00 {
  color: #04bd00 !important;
}
.clr-f60000 {
  color: #f60000 !important;
}
.bg-redlight {
  background: #f600001c !important;
}
.bg-light {
  background: #03bd001a !important;
}
.chakra-popover__content {
  width: auto !important;
}
.bg-light-sucess {
  background: rgba(4, 189, 0, 0.1);
  color: #04bd00;
  font-size: 12px;
  padding: 6px 17px !important;
  border-radius: 20px !important;
}
.bordh {
  border-top: 1px solid #f4f5f6;
  border-bottom: 5px solid var(--chakra-colors-secondary);
}
.popwidth {
  width: 240px !important;
}

.bg-b3e7bcd1 {
  background-color: #b3e7bcd1 !important;
}

.clr-0fba2a {
  color: #0fba2a !important;
}

.bg-ec1c24 {
  background-color: rgba(236, 36, 28, 0.1) !important;
}

.top-left-8px {
  top: -4px;
  left: -4px;
}

.clr-f95c27 {
  color: var(--chakra-colors-secondary) !important;
}

.bdr-f95c27 {
  border: 2px solid var(--chakra-colors-secondary) !important;
}

.clr-053c6D {
  color: var(--chakra-colors-primary) !important;
}

.bdr-053c6D {
  border: 2px solid var(--chakra-colors-primary) !important;
}

.custborbot {
  border-bottom: 4px solid var(--chakra-colors-secondary) !important;
  border-radius: 0px 0px 4px 4px;
}

.chakra-menu__menu-list {
  min-width: auto !important;
  padding: 2px 0px !important;
}
.pending-bg {
  background: rgba(241, 178, 56, 0.1) !important;
  color: #f1b238 !important;
}
.approve-bg {
  background: rgba(4, 189, 0, 0.1) !important;
  color: #04bd00 !important;
}
.reject-bg {
  background: rgba(236, 28, 36, 0.1) !important;
  color: #ec1c24 !important;
}
.bg-img-background {
  height: 100vh;
  background: #ddd;
}
.bg-ddd-card {
  border-radius: 8px;
  height: 108px;
  width: 100%;
  @media (max-width: 640px) {
    height: 100px !important;
    width: 100% !important;
  }
}

.edit_hover_class:hover .lock {
  display: block;
}
.edit_hover_class .lock {
  display: none;
}
.bg-ddd {
  background: #ddd;
}
.bg-transparent {
  background: rgba(255, 255, 255, 0.5);
}
.popover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: none;
}
.box:hover .icon {
  display: block;
}
.icon:hover + .popover {
  display: block;
}
.icon {
  display: none;
  position: absolute;
}
.box {
  position: relative;
}
.cust-bg-fff {
  background: #fff !important;
  color: #000 !important;
  font-size: 13px !important;
  border-radius: 10px !important;
  padding: 10px !important;
}
.user-100vh {
  height: 100vh !important;
}
@media (max-width: 821px) and (min-width: 575px) {
  .tb-100vh {
    height: 100vh;
  }
}
