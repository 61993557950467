// Sports

.turf_Image {
    width: 100%;
    height: 100%;
  }
  
  .social_image {
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 100%;
    border: 2px solid var(--chakra-colors-primary) !important;;
  }
  
  .activites_image {
    width: 100%;
    height: 140px;
  }
  
  .clr-7c7c7c {
    color: #7c7c7c;
  }
  
  .turf_scroller {
    height: 26rem;
    overflow: auto;

    @media  (max-width: 641px) {
      height: 32rem;
    }
  }
  
  .minus-mtop5px {
    margin-top: -5px;
  }

  .bg-f8f8f8 {
    background-color: #f8f8f8;
  }

  .txt-center {
    text-align: center !important;
  }

  .clr-white {
    color: #fff !important;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-left: 16px solid var(--chakra-colors-primary);
    border-bottom: 16px solid transparent;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-right: 16px solid var(--chakra-colors-primary);
    border-bottom: 16px solid transparent;
  }

  