//font-size
.fsize1 {
  font-size: 1px ;
}
.fsize2 {
  font-size: 2px ;
}
.fsize3 {
  font-size: 3px ;
}
.fsize4 {
  font-size: 4px ;
}
.fsize5 {
  font-size: 5px ;
}
.fsize6 {
  font-size: 6px ;
}
.fsize7 {
  font-size: 7px ;
}
.fsize8 {
  font-size: 8px ;
}
.fsize9 {
  font-size: 9px ;
}
.fsize10 {
  font-size: 10px !important;
}
.fsize11 {
  font-size: 11px ;
}
.fsize12 {
  font-size: 12px !important;
}
.fsize13 {
  font-size: 13px ;
}
.fsize14 {
  font-size: 14px !important;
}
.fsize15 {
  font-size: 15px ;
}
.fsize16 {
  font-size: 16px !important;
}
.fsize17 {
  font-size: 17px ;
}
.fsize18 {
  font-size: 18px !important;
}
.fsize19 {
  font-size: 19px ;
}
.fsize20 {
  font-size: 20px ;
}
.fsize21 {
  font-size: 21px ;
}
.fsize22 {
  font-size: 22px ;
}
.fsize23 {
  font-size: 23px ;
}
.fsize24 {
  font-size: 24px ;
}
.fsize25 {
  font-size: 25px ;
}
.fsize26 {
  font-size: 26px ;
}
.fsize27 {
  font-size: 27px ;
}
.fsize28 {
  font-size: 28px ;
}
.fsize29 {
  font-size: 29px ;
}
.fsize30 {
  font-size: 30px ;
}
.fsize31 {
  font-size: 31px ;
}
.fsize32 {
  font-size: 32px ;
}
.fsize33 {
  font-size: 33px ;
}
.fsize34 {
  font-size: 34px ;
}
.fsize35 {
  font-size: 35px ;
}
.fsize36 {
  font-size: 36px ;
}
.fsize37 {
  font-size: 37px ;
}
.fsize38 {
  font-size: 38px ;
}
.fsize39 {
  font-size: 39px ;
}
.fsize40 {
  font-size: 40px ;
}
.fsize41 {
  font-size: 41px ;
}
.fsize42 {
  font-size: 42px ;
}
.fsize43 {
  font-size: 43px ;
}
.fsize44 {
  font-size: 44px ;
}
.fsize45 {
  font-size: 45px ;
}
.fsize46 {
  font-size: 46px ;
}
.fsize47 {
  font-size: 47px ;
}
.fsize48 {
  font-size: 48px ;
}
.fsize49 {
  font-size: 49px ;
}
.fsize50 {
  font-size: 50px ;
}
.fsize54 {
  font-size: 54px ;
}
.fsize74 {
  font-size: 74px ;
}

@media (max-width: 820px) and (min-width: 641px) {
  .md-fsize1 {
    font-size: 1px !important;
  }
  .md-fsize2 {
    font-size: 2px !important;
  }
  .md-fsize3 {
    font-size: 3px !important;
  }
  .md-fsize4 {
    font-size: 4px !important;
  }
  .md-fsize5 {
    font-size: 5px !important;
  }
  .md-fsize6 {
    font-size: 6px !important;
  }
  .md-fsize7 {
    font-size: 7px !important;
  }
  .md-fsize8 {
    font-size: 8px !important;
  }
  .md-fsize9 {
    font-size: 9px !important;
  }
  .md-fsize10 {
    font-size: 10px !important;
  }
  .md-fsize11 {
    font-size: 11px !important;
  }
  .md-fsize12 {
    font-size: 12px !important;
  }
  .md-fsize13 {
    font-size: 13px !important;
  }
  .md-fsize14 {
    font-size: 14px !important;
  }
  .md-fsize15 {
    font-size: 15px !important;
  }
  .md-fsize16 {
    font-size: 16px !important;
  }
  .md-fsize17 {
    font-size: 17px !important;
  }
  .md-fsize18 {
    font-size: 18px !important;
  }
  .md-fsize19 {
    font-size: 19px !important;
  }
  .md-fsize20 {
    font-size: 20px !important;
  }
  .md-fsize21 {
    font-size: 21px !important;
  }
  .md-fsize22 {
    font-size: 22px !important;
  }
  .md-fsize23 {
    font-size: 23px !important;
  }
  .md-fsize24 {
    font-size: 24px !important;
  }
  .md-fsize25 {
    font-size: 25px !important;
  }
  .md-fsize26 {
    font-size: 26px !important;
  }
  .md-fsize27 {
    font-size: 27px !important;
  }
  .md-fsize28 {
    font-size: 28px !important;
  }
  .md-fsize29 {
    font-size: 29px !important;
  }
  .md-fsize30 {
    font-size: 30px !important;
  }
  .md-fsize31 {
    font-size: 31px !important;
  }
  .md-fsize32 {
    font-size: 32px !important;
  }
  .md-fsize33 {
    font-size: 33px !important;
  }
  .md-fsize34 {
    font-size: 34px !important;
  }
  .md-fsize35 {
    font-size: 35px !important;
  }
  .md-fsize36 {
    font-size: 36px !important;
  }
  .md-fsize37 {
    font-size: 37px !important;
  }
  .md-fsize38 {
    font-size: 38px !important;
  }
  .md-fsize39 {
    font-size: 39px !important;
  }
  .md-fsize40 {
    font-size: 40px !important;
  }
  .md-fsize41 {
    font-size: 41px !important;
  }
  .md-fsize42 {
    font-size: 42px !important;
  }
  .md-fsize43 {
    font-size: 43px !important;
  }
  .md-fsize44 {
    font-size: 44px !important;
  }
  .md-fsize45 {
    font-size: 45px !important;
  }
  .md-fsize46 {
    font-size: 46px !important;
  }
  .md-fsize47 {
    font-size: 47px !important;
  }
  .md-fsize48 {
    font-size: 48px !important;
  }
  .md-fsize49 {
    font-size: 49px !important;
  }
  .md-fsize50 {
    font-size: 50px !important;
  }
}

@media (max-width: 640px) {
  .sm-fsize1 {
    font-size: 1px !important;
  }
  .sm-fsize2 {
    font-size: 2px !important;
  }
  .sm-fsize3 {
    font-size: 3px !important;
  }
  .sm-fsize4 {
    font-size: 4px !important;
  }
  .sm-fsize5 {
    font-size: 5px !important;
  }
  .sm-fsize6 {
    font-size: 6px !important;
  }
  .sm-fsize7 {
    font-size: 7px !important;
  }
  .sm-fsize8 {
    font-size: 8px !important;
  }
  .sm-fsize9 {
    font-size: 9px !important;
  }
  .sm-fsize10 {
    font-size: 10px !important;
  }
  .sm-fsize11 {
    font-size: 11px !important;
  }
  .sm-fsize12 {
    font-size: 12px !important;
  }
  .sm-fsize13 {
    font-size: 13px !important;
  }
  .sm-fsize14 {
    font-size: 14px !important;
  }
  .sm-fsize15 {
    font-size: 15px !important;
  }
  .sm-fsize16 {
    font-size: 16px !important;
  }
  .sm-fsize17 {
    font-size: 17px !important;
  }
  .sm-fsize18 {
    font-size: 18px !important;
  }
  .sm-fsize19 {
    font-size: 19px !important;
  }
  .sm-fsize20 {
    font-size: 20px !important;
  }
  .sm-fsize21 {
    font-size: 21px !important;
  }
  .sm-fsize22 {
    font-size: 22px !important;
  }
  .sm-fsize23 {
    font-size: 23px !important;
  }
  .sm-fsize24 {
    font-size: 24px !important;
  }
  .sm-fsize25 {
    font-size: 25px !important;
  }
  .sm-fsize26 {
    font-size: 26px !important;
  }
  .sm-fsize27 {
    font-size: 27px !important;
  }
  .sm-fsize28 {
    font-size: 28px !important;
  }
  .sm-fsize29 {
    font-size: 29px !important;
  }
  .sm-fsize30 {
    font-size: 30px !important;
  }
  .sm-fsize31 {
    font-size: 31px !important;
  }
  .sm-fsize32 {
    font-size: 32px !important;
  }
  .sm-fsize33 {
    font-size: 33px !important;
  }
  .sm-fsize34 {
    font-size: 34px !important;
  }
  .sm-fsize35 {
    font-size: 35px !important;
  }
  .sm-fsize36 {
    font-size: 36px !important;
  }
  .sm-fsize37 {
    font-size: 37px !important;
  }
  .sm-fsize38 {
    font-size: 38px !important;
  }
  .sm-fsize39 {
    font-size: 39px !important;
  }
  .sm-fsize40 {
    font-size: 40px !important;
  }
  .sm-fsize41 {
    font-size: 41px !important;
  }
  .sm-fsize42 {
    font-size: 42px !important;
  }
  .sm-fsize43 {
    font-size: 43px !important;
  }
  .sm-fsize44 {
    font-size: 44px !important;
  }
  .sm-fsize45 {
    font-size: 45px !important;
  }
  .sm-fsize46 {
    font-size: 46px !important;
  }
  .sm-fsize47 {
    font-size: 47px !important;
  }
  .sm-fsize48 {
    font-size: 48px !important;
  }
  .sm-fsize49 {
    font-size: 49px !important;
  }
  .sm-fsize50 {
    font-size: 50px !important;
  }
}

//animation
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 40px;
  font-weight: 900 !important;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

//second
.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #df8453;
  --color-2: #3d8dae;
  --color-3: #e4a9a8;
}

.title-word-2 {
  --color-1: #dbad4a;
  --color-2: #accfcb;
  --color-3: #17494d;
}

.title-word-3 {
  --color-1: #accfcb;
  --color-2: #e4a9a8;
  --color-3: #accfcb;
}

.title-word-4 {
  --color-1: #3d8dae;
  --color-2: #df8453;
  --color-3: #e4a9a8;
}

@keyframes color-animation {
  0% {
    color: var(--color-1);
  }
  32% {
    color: var(--color-1);
  }
  33% {
    color: var(--color-2);
  }
  65% {
    color: var(--color-2);
  }
  66% {
    color: var(--color-3);
  }
  99% {
    color: var(--color-3);
  }
  100% {
    color: var(--color-1);
  }
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  display: flex;
  gap: 6px;
}

//third
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
.new-word {
  position: relative;
}

.new-word h2 {
  color: #fff;
  font-size: 80px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.new-word h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #03a9f4;
}

.new-word h2:nth-child(2) {
  color: #03a9f4;
  animation: animatehy 4s ease-in-out infinite;
}

@keyframes animatehy {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

//four

.text_shadows {
  text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(#fff), 9px 9px var(#fff),
    12px 12px 0 var(#fff);
  font-family: bungee, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(1rem + 3vw);
  text-align: center;
  margin: 0;
  color: var(#fff);
  //color: transparent;
  //background-color: white;
  //background-clip: text;
  color: #fff;
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 var(#fff);
  }
  20% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary);
  }
  30% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary),
      9px 9px var(#fff);
  }
  40% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary),
      9px 9px var(#fff), 12px 12px 0 var(--color-quinary);
  }
  50% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary),
      9px 9px var(#fff), 12px 12px 0 var(--color-quinary);
  }
  60% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary),
      9px 9px var(#fff), 12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary),
      9px 9px var(#fff);
  }
  80% {
    text-shadow: 3px 3px 0 var(#fff), 6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(#fff);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

//five
.line {
  width: 100%;
  overflow: hidden;
  padding: 0;
}

/* flipping class and key frames*/
.flipX {
  animation: 1s anim-flipX ease infinite;
}
@keyframes anim-flipX {
  0% {
    opacity: 0;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }
  100% {
    /* animate nothing to pause animation at the end */
    opacity: 1;
    transform: rotateX(720deg);
  }
}

/* lineup class and keyframes */
.lineUp {
  animation: 2s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
/* flipY class and keyframes*/
.flipY {
  animation-name: spin, depth;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-360deg);
  }
}
@keyframes depth {
  0% {
    text-shadow: 0 0 black;
  }
  25% {
    text-shadow: 1px 0 black, 2px 0 black, 3px 0 black, 4px 0 black, 5px 0 black;
  }
  50% {
    text-shadow: 0 0 black;
  }
  75% {
    text-shadow: -1px 0 black, -2px 0 black, -3px 0 black, -4px 0 black,
      -5px 0 black;
  }
  100% {
    text-shadow: 0 0 black;
  }
}

/* subtle zoom to attention and then back */
.pop-outin {
  animation: 2s anim-popoutin ease infinite;
}

@keyframes anim-popoutin {
  0% {
    color: black;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: red;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: black;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}

.fromtop {
  animation: 2s anim-fromtop linear infinite;
}
@keyframes anim-fromtop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  25% {
    opacity: 1;
    transform: translateY(0%);
  }
  50% {
  }
  100% {
  }
}
